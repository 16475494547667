import * as ReactDOM from 'react-dom/client';

require('./src/components/prism-coldark-dark.css');
require('./src/styles/global.css');

/* Remedy for production-build minified errors, not present in dev build, as per 
 * https://github.com/gatsbyjs/gatsby/discussions/36232#discussioncomment-6250516
 */
export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
export const shouldUpdateScroll = () => true;